// JavaScript
import React, { useContext, useState } from "react";
import { Button, Form, Spinner } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { UserPlus } from "lucide-react";
import PersonalDataSection from "../../components/patient/personalDataSection";
import AddressSection from "../../components/patient/addressSection";
import FamilyInfoSection from "../../components/patient/familyInfoSection";
import AdditionalInfoSection from "../../components/patient/additionalInfoSection";
import UnauthorizedPage from "../unauthorizedPage";
import { SuccessModal } from "../../components/patient/successModal";
import { createPatient } from "../../services/api";
import { AuthContext } from "../../contexts/AuthContext";

const RegisterPatientPage = () => {
  const { role, user, isAuthLoading } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let formData = new FormData(e.currentTarget);

    const patientData = {
      name: formData.get("fullName"),
      therapistRegistrationNumber: user.uid,
      cpf: formData.get("cpf"),
      birthDate: formData.get("birthDate"),
      maritalStatus: formData.get("maritalStatus"),
      gender: formData.get("gender"),
      address: `${formData.get("address")}, ${formData.get(
        "city"
      )} - ${formData.get("state")}`,
      contact1: formData.get("phoneNumber"),
      contact2: formData.get("alternativePhoneNumber") || "",
      email: formData.get("email"),
      motherName: formData.get("motherName"),
      fatherName: formData.get("fatherName"),
      profession: formData.get("profession"),
      educationLevel: formData.get("educationLevel"),
      referredBy: formData.get("referredBy"),
      insuranceCard: formData.get("insuranceCard") || "",
      responsibleName: formData.get("responsibleName") || "",
      responsibleBirthDate: formData.get("responsibleBirthDate") || "",
      contactPersonNames: [
        formData.get("emergencyContact1") || "",
        formData.get("emergencyContact2") || "",
      ].filter((contact) => contact !== ""),
      sessions: [],
    };

    try {
      const response = await createPatient(patientData);

      console.log(response);
      setShowModal(true);
    } catch (error) {
      console.error("Error creating patient:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/dashboard");
  };

  if (role !== "therapist" && !isAuthLoading) {
    return <UnauthorizedPage />;
  }

  return (
    <>
      {isAuthLoading ? (
        <div className="mx-auto max-w-5xl py-10 flex flex-col">
          <Spinner color="secondary" />
        </div>
      ) : (
        <>
          <Form
            className="mx-auto max-w-6xl flex flex-col p-6 grid grid-cols-1 gap-4"
            onSubmit={onSubmit}
            validationBehavior="native"
          >
            <h1 className="text-2xl font-bold mb-4">Cadastrar Paciente</h1>
            <PersonalDataSection />
            <AddressSection />
            <FamilyInfoSection />
            <AdditionalInfoSection />

            <Button
              type="submit"
              className="mt-4 w-1/4 flex ml-auto"
              isLoading={isSubmitting}
              color="secondary"
              startContent={<UserPlus size={16} />}
            >
              Cadastrar
            </Button>
          </Form>
          {showModal && (
            <SuccessModal
              isOpen={showModal}
              onClose={handleCloseModal}
              navigate={navigate}
            />
          )}
        </>
      )}
    </>
  );
};

export default RegisterPatientPage;
