import React, { useState } from "react";
import { Card, CardHeader, Input, Checkbox } from "@nextui-org/react";

const FamilyInfoSection = () => {
  const [isUnderage, setIsUnderage] = useState(false);

  return (
    <>
      <Card className="p-6 grid grid-cols-1 md:grid-cols-2 gap-4" shadow="sm">
        <CardHeader className="p-0 col-span-full flex-col !items-start">
          <h4 className="font-medium text-large">Informações Familiares</h4>
        </CardHeader>
        <Checkbox
          isSelected={isUnderage}
          onValueChange={setIsUnderage}
          className="col-span-full !items-start"
        >
          Paciente é menor de idade?
        </Checkbox>
        <Input
          isDisabled={!isUnderage}
          isRequired={isUnderage}
          label="Nome do Responsável"
          labelPlacement="outside"
          name="responsibleName"
          placeholder="Digite o nome do responsável"
          type="text"
          validate={(value) =>
            isUnderage && !value ? "Campo obrigatório" : true
          }
        />
        <Input
          isDisabled={!isUnderage}
          isRequired={isUnderage}
          label="Data de Nascimento do Responsável"
          labelPlacement="outside"
          name="responsibleBirthDate"
          type="date"
          validate={(value) =>
            isUnderage && !value ? "Campo obrigatório" : true
          }
        />
        <Input
          isDisabled={!isUnderage}
          isRequired={isUnderage}
          label="Nome da Mãe"
          labelPlacement="outside"
          name="motherName"
          placeholder="Digite o nome da mãe"
          type="text"
          validate={(value) =>
            isUnderage && !value ? "Campo obrigatório" : true
          }
        />
        <Input
          isDisabled={!isUnderage}
          isRequired={isUnderage}
          label="Nome do Pai"
          labelPlacement="outside"
          name="fatherName"
          placeholder="Digite o nome do pai"
          type="text"
          validate={(value) =>
            isUnderage && !value ? "Campo obrigatório" : true
          }
        />
      </Card>
    </>
  );
};

export default FamilyInfoSection;
