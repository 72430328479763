import React from "react";
import { Card, CardBody, Button } from "@nextui-org/react";
import { ArrowLeft, ShieldAlert } from "lucide-react";
import { useNavigate } from "react-router-dom";

const UnauthorizedPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-[80vh] p-4">
      <Card className="max-w-md w-full">
        <CardBody className="flex flex-col items-center gap-6 py-8">
          <ShieldAlert className="w-16 h-16 text-secondary" />

          <div className="text-center space-y-2">
            <h1 className="text-2xl font-bold text-default-900">
              Ops! Você se perdeu?
            </h1>
            <p className="text-default-600">
              Esta pagina não está disponível para você.
            </p>
          </div>

          <Button
            color="secondary"
            variant="light"
            startContent={<ArrowLeft size={16} />}
            onPress={() => navigate(-1)}
          >
            Voltar
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default UnauthorizedPage;
