import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import "moment/locale/pt-br";
import { parseDate, CalendarDate } from "@internationalized/date";
import { AuthContext } from "../contexts/AuthContext";
import {
  getAllPatientsForTherapist,
  createPatient,
  createSession,
} from "../services/api";
import { Alert, Card, CardBody, Skeleton } from "@nextui-org/react";

import UnauthorizedPage from "./unauthorizedPage";
import ActionsPanel from "../components/dashboard/actionsPanel";
import CalendarSection from "../components/dashboard/calendarSection";
import SessionsList from "../components/dashboard/sessionsList";
import NewSessionModal from "../components/dashboard/newSessionModal";
import SessionActionsModal from "../components/dashboard/sessionActionsModal";

import "../styles/dashboardPageStyle.css";

moment.locale("pt-br");

const DashboardPage = () => {
  // Authentication context
  const { role, user, isAuthLoading } = useContext(AuthContext);

  // Loading and error states
  const [isContentLoading, setIsContentLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formError, setFormError] = useState(null);

  // Data states
  const [sessions, setSessions] = useState([]);
  const [patients, setPatients] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    parseDate(moment().format("YYYY-MM-DD"))
  );
  const [filteredSessions, setFilteredSessions] = useState([]);

  // Modal states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  // Load patients and sessions
  useEffect(() => {
    const fetchPatientData = async () => {
      if (!user?.uid) return;

      try {
        const response = await getAllPatientsForTherapist(user.uid);
        const patientsData = response.data;
        setPatients(patientsData);

        // Extract all sessions with patient info
        const allSessions = patientsData.flatMap((patient) =>
          (patient.sessions || []).map((session) => ({
            id: session.id,
            title: patient.name,
            date: new Date(session.date),
            endTime: new Date(
              moment(session.date)
                .add(session.sessionDuration, "minutes")
                .format()
            ),
            duration: session.sessionDuration,
            patientId: patient.id,
            patientName: patient.name,
            sessionDetails: session,
          }))
        );

        setSessions(allSessions);
      } catch (err) {
        setError("Erro ao carregar sessões");
        console.error(err);
      } finally {
        setIsContentLoading(false);
      }
    };

    fetchPatientData();
  }, [user?.uid]);

  // Filter sessions when date changes
  useEffect(() => {
    if (selectedDate && sessions.length > 0) {
      const dateStr = selectedDate.toString();
      const filtered = sessions.filter(
        (session) => moment(session.date).format("YYYY-MM-DD") === dateStr
      );
      filtered.sort((a, b) => a.date - b.date);
      setFilteredSessions(filtered);
    } else {
      setFilteredSessions([]);
    }
  }, [selectedDate, sessions]);

  // Handlers
  const handleDateChange = (date) => {
    // Force creation of a new date object to ensure React detects the change
    const newDate = new CalendarDate(date.year, date.month, date.day);
    setSelectedDate(newDate);
  };

  const openNewSessionModal = () => {
    setFormError(null);
    setIsModalOpen(true);
  };

  const handleEventSelect = (session) => {
    setSelectedEvent(session);
    setIsEventModalOpen(true);
  };

  const handleModalSubmit = async (
    e,
    { sessionData, patientOption, selectedPatient }
  ) => {
    setModalLoading(true);
    setFormError(null);

    // Debug logs
    console.log("Form submitted with:", {
      patientOption,
      selectedPatient,
      sessionData,
    });

    // Validate form
    if (!sessionData.sessionDate || !sessionData.sessionTime) {
      setFormError("Data e horário da sessão são obrigatórios.");
      setModalLoading(false);
      return;
    }

    // Prepare session date time
    const sessionDateTime = moment(
      `${sessionData.sessionDate.toString()} ${sessionData.sessionTime}`,
      "YYYY-MM-DD HH:mm"
    );

    // Validate date
    if (sessionDateTime.isBefore(moment().startOf("day"))) {
      setFormError("A data da sessão não pode ser anterior a hoje.");
      setModalLoading(false);
      return;
    }

    try {
      let patientId;
      let patientName;

      // Handle patient creation or selection
      if (patientOption === "new") {
        // Create new patient
        const formData = new FormData(e.currentTarget);
        const patientData = {
          name: formData.get("fullName"),
          therapistRegistrationNumber: user.uid,
          cpf: formData.get("cpf") || "",
          birthDate: formData.get("birthDate"),
          maritalStatus: formData.get("maritalStatus"),
          gender: formData.get("gender"),
          sessions: [],
        };

        const patientResponse = await createPatient(patientData);
        patientId = patientResponse.data.id;
        patientName = patientData.name;
        setPatients((prev) => [...prev, { id: patientId, name: patientName }]);
      } else {
        // Use existing patient
        console.log("Using existing patient:", selectedPatient);
        if (!selectedPatient) {
          setFormError("Por favor, selecione um paciente.");
          setModalLoading(false);
          return;
        }
        patientId = selectedPatient;
        patientName = patients.find((p) => p.id === patientId)?.name || "";
      }

      // Create session
      const sessionPayload = {
        date: sessionDateTime.toISOString(),
        sessionDuration: sessionData.sessionDuration,
        contractConsent: "",
        medicalRecordHistory: "",
        sessionRecord: "",
        anamnesis: "",
        attachments: [],
      };

      const response = await createSession(sessionPayload, patientId);

      // Add new session to state
      const newSession = {
        id: response.data.id,
        title: patientName,
        date: sessionDateTime.toDate(),
        endTime: sessionDateTime
          .clone()
          .add(sessionData.sessionDuration, "minutes")
          .toDate(),
        duration: sessionData.sessionDuration,
        patientId: patientId,
        patientName: patientName,
        sessionDetails: sessionPayload,
      };

      setSessions((prev) => [...prev, newSession]);
      setIsModalOpen(false);
    } catch (err) {
      setFormError("Erro ao criar paciente ou sessão");
      console.error(err);
    } finally {
      setModalLoading(false);
    }
  };

  // Access control
  if (role !== "therapist" && !isAuthLoading) {
    return <UnauthorizedPage />;
  }

  return (
    <div className="mx-auto max-w-5xl py-10 px-4">
      <Card shadow="sm" className="w-full h-[530px] p-4">
        <CardBody>
          <div className="flex flex-col md:flex-row w-full">
            {isAuthLoading || isContentLoading ? (
              // Skeleton structure (no fade)
              <>
                <div className="md:w-1/3 p-2">
                  <div className="flex flex-col gap-4">
                    <Skeleton className="h-8 w-3/4 rounded-lg mb-2" />
                    <Skeleton className="h-10 w-full rounded-lg mb-2" />
                    {/* ...other skeleton elements... */}
                  </div>
                </div>

                <div className="md:w-1/3 p-2">
                  <div className="flex flex-col items-center">
                    <Skeleton className="h-8 w-40 rounded-lg mb-4" />
                    <div className="h-[320px] w-full flex items-center justify-center">
                      <Skeleton className="h-64 w-full max-w-xs rounded-lg" />
                    </div>
                    <Skeleton className="h-10 w-40 rounded-lg mt-4" />
                  </div>
                </div>

                <div className="md:w-1/3 p-2">
                  <div className="flex flex-col">
                    <Skeleton className="h-8 w-40 rounded-lg mb-4" />
                    <div className="flex flex-col gap-2">
                      {[1, 2, 3].map((i) => (
                        <Skeleton key={i} className="h-20 w-full rounded-lg" />
                      ))}
                    </div>
                  </div>
                </div>
              </>
            ) : error ? (
              <div className="w-full">
                <Alert color="error" title="Erro ao carregar sessões" />
              </div>
            ) : (
              // Actual content with fade-in animation on individual sections
              <>
                <div className="md:w-1/3 p-2 fade-in">
                  <ActionsPanel
                    onNewSession={openNewSessionModal}
                    patientsCount={patients.length}
                    todaySessions={filteredSessions.length}
                    totalSessions={sessions.length}
                  />
                </div>

                <div className="md:w-1/3 p-2 fade-in">
                  <CalendarSection
                    selectedDate={selectedDate}
                    handleDateChange={handleDateChange}
                  />
                </div>

                <div className="md:w-1/3 p-2 fade-in">
                  <SessionsList
                    sessions={filteredSessions}
                    allSessions={sessions}
                    onSessionSelect={handleEventSelect}
                    selectedDate={selectedDate}
                    onDateChange={handleDateChange}
                  />
                </div>
              </>
            )}
          </div>
        </CardBody>
      </Card>

      {/* Modals remain the same */}
      <NewSessionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleModalSubmit}
        selectedDate={selectedDate}
        patients={patients}
        isLoading={modalLoading}
        formError={formError}
      />

      <SessionActionsModal
        isOpen={isEventModalOpen}
        onClose={() => setIsEventModalOpen(false)}
        session={selectedEvent}
      />
    </div>
  );
};

export default DashboardPage;
