import React, { useState } from "react";
import {
  Button,
  Modal,
  Header,
  Segment,
  Form,
  Container,
  Card,
  Image,
  SegmentGroup,
} from "semantic-ui-react";

import { DateTimeInput } from "semantic-ui-calendar-react";
import "../App.css";

const therapists = [
  // { key: 1, name: "Andressa Klakoski", description: "Psicóloga Clínica Especialista em Psicologia sistêmica familiar Pós Graduada em Teoria Psicanalítica", subDescription: "Pós-graduanda em Avaliação Psicológica", profilePictureURL: "https://psycoplamdb.blob.core.windows.net/profilepictures/andressa_pp.jpg" },
  // { key: 2, name: "Marcos Vinícius Nakaguishi", description: "Médico Especialista em Psiquiatria", subDescription: "Pós graduado em Psicoterapia Cognitivo Comportamental", profilePictureURL: "https://psycoplamdb.blob.core.windows.net/profilepictures/marcos_pp.jpg" },
  // { key: 3, name: "Mariene de Moraes Julio", description: "Psicóloga Clínica Pós graduada em Saúde Mental e Desafios Contemporâneos: Dependência Química e Promoção da Saúde", subDescription: "Pós-graduanda em Psicologia do Trânsito e Neuropsicologia", profilePictureURL: "https://psycoplamdb.blob.core.windows.net/profilepictures/mariene_pp.jpg" },
  // { key: 4, name: "Pamela van Erven", description: "Psicóloga Clínica Especialista em Análise do Comportamento Aplicada/ Saúde Mental", subDescription: "Pós graduanda em Neuropsicologia", profilePictureURL: "https://psycoplamdb.blob.core.windows.net/profilepictures/pamela_pp.jpg" },
  // { key: 5, name: "Tatiane Marili Pereira Machado", description: "Psicóloga Clínica Especialista em psicologia corporal Formação em liberação em psicossomática muscular", subDescription: "Pós graduação em Sexologia", profilePictureURL: "https://psycoplamdb.blob.core.windows.net/profilepictures/tatiane_pp.jpg" },
];

const SchedulePage = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTherapist, setSelectedTherapist] = useState("");
  const [userInfo, setUserInfo] = useState({ name: "", email: "" });
  const [modalOpen, setModalOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const onChange = (event, data) => {
    setSelectedDate(data.value);
    setStep(2);
  };

  const validateEmail = (email) => {
    // Regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleConfirm = () => {
    const message = `Olá, gostaria de agendar uma consulta para o dia ${selectedDate} com a(o) psicóloga(o) ${selectedTherapist}.\nMeu nome é ${userInfo.name} e meu email é ${userInfo.email}.`;
    const contact = "+5541999402722";

    // Check if the user is using WhatsApp Web or the WhatsApp app
    const isWhatsAppWeb = /WhatsApp\/\d+\.\d+\.\d+/.test(navigator.userAgent);

    if (isWhatsAppWeb) {
      // Redirect the user to WhatsApp Web with the pre-filled message
      window.location.href = `https://web.whatsapp.com/send?phone=${contact}&text=${encodeURIComponent(message)}`;
    } else {
      // Redirect the user to the WhatsApp app with the pre-filled message
      window.open(`https://api.whatsapp.com/send?phone=${contact}&text=${encodeURIComponent(message)}`);
    }

    setModalOpen(false);
  };

  const handleReview = () => {
    if (!userInfo.name) {
      setNameError(true);
    } if (!validateEmail(userInfo.email)) {
      setEmailError(true);
    } else {
      setModalOpen(true);
    }
  };

  var minDate = new Date();
  var maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 2);

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "60vh",
      }}
    >

      <Header as="h1">Agende sua consulta</Header>

      <SegmentGroup style={{ padding: "15px", overflowX: "auto" }}>
        <Form>
          <Segment disabled={step !== 1} raised>
            <Form.Field error={nameError}>
              <DateTimeInput
                inline="true"
                onChange={onChange}
                minDate={minDate}
                maxDate={maxDate}
                disableMinute={true}
              />
            </Form.Field>
          </Segment>

          {false && ( //temporary
            <Segment disabled={step !== 2} raised>
              <Card.Group itemsPerRow={3} style={{ display: "flex", flexWrap: "nowrap", overflowX: "auto" }}>
                {therapists.map((therapist, index) => (
                  <Card
                    style={{
                      borderBottom:
                        selectedTherapist === therapist.name
                          ? "8px solid"
                          : "",
                      borderColor: "purple",
                      width: "300px", // Add this line to set a fixed width for the cards
                    }}
                    key={index}
                    onClick={() => {
                      setSelectedTherapist(therapist.name);
                      setStep(3);
                    }}
                  >
                    <Image
                      src={therapist.profilePictureURL}
                      wrapped
                      ui={true}
                      size="medium"
                      rounded
                      bordered
                    />
                    <Card.Content>
                      <Card.Header>{therapist.name}</Card.Header>
                      <Card.Meta>
                        <span className="date">
                          {therapist.subDescription}
                        </span>
                      </Card.Meta>
                      <Card.Description>
                        {therapist.description}
                      </Card.Description>
                    </Card.Content>
                  </Card>
                ))}
              </Card.Group>
            </Segment>
          )}

          {step >= 2 && (
            <Segment raised>
              <Form.Input
                fluid
                type="text"
                label="Nome"
                placeholder="Digite seu nome"
                disabled={step > 3}
                error={nameError}
                onChange={(e) => {
                  setUserInfo({ ...userInfo, name: e.target.value });
                  setNameError(false);
                }}
              />
              <Form.Input
                fluid
                type="email"
                label="Email"
                placeholder="Digite seu email"
                disabled={step > 3}
                error={emailError}
                onChange={(e) => {
                  setUserInfo({ ...userInfo, email: e.target.value });
                  setEmailError(false);
                }}
              />
            </Segment>
          )}
        </Form>
      </SegmentGroup>

      {step >= 2 && (
        <Segment>
          <Button
            disabled={step < 2}
            type="button"
            onClick={() => setStep(step - 1)}
          >
            Voltar
          </Button>

          <Button
            disabled={step < 2}
            type="button"
            color="purple"
            onClick={() => handleReview()}
          >
            Revisar e agendar consulta
          </Button>
        </Segment>
      )}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Header>Revise sua consulta</Modal.Header>
        <Modal.Content>
          <p>Data e horario da consulta: {selectedDate && selectedDate.toString()}</p>
          <p>Psicologa(o): {selectedTherapist}</p>
          <p>Nome do paciente: {userInfo.name}</p>
          <p>Email do paciente: {userInfo.email}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={handleConfirm} color="purple">
            Agendar consulta
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
};

export default SchedulePage;
