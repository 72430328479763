import React, { useEffect, useState, useContext } from "react";
import { Spinner, Card, CardBody, Button, Divider } from "@nextui-org/react";
import { User, Mail, Phone, Calendar, Clock } from "lucide-react";
import UnauthorizedPage from "../unauthorizedPage";
import { getAllPatientsForTherapist } from "../../services/api";
import { AuthContext } from "../../contexts/AuthContext";

const TherapistPatientsPage = () => {
  const { role, user, isAuthLoading } = useContext(AuthContext);
  const [patients, setPatients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchPatients() {
      if (!user?.uid) return;

      setIsLoading(true);
      try {
        const response = await getAllPatientsForTherapist(user.uid);
        setPatients(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    }

    if (!isAuthLoading) {
      fetchPatients();
    }
  }, [user, isAuthLoading]);

  if (role !== "therapist" && !isAuthLoading) {
    return <UnauthorizedPage />;
  }

  if (isAuthLoading || isLoading) {
    return (
      <div className="mx-auto max-w-5xl py-10 flex flex-col items-center">
        <Spinner color="secondary" label="Carregando pacientes..." />
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-6xl py-10 px-4">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <h1 className="text-2xl font-bold">Meus Pacientes</h1>
          <p className="text-default-500">
            Gerencie seus pacientes e suas informações
          </p>
        </div>

        <Divider />

        {error && (
          <Card className="bg-danger-50 border-danger-200">
            <CardBody>
              <p className="text-danger">{error}</p>
            </CardBody>
          </Card>
        )}

        {patients.length === 0 ? (
          <Card shadow="sm">
            <CardBody className="text-center py-8">
              <User className="w-12 h-12 mx-auto mb-4 text-default-400" />
              <h3 className="font-semibold text-xl mb-2">Nenhum Paciente</h3>
              <p className="text-default-500 mb-4">
                Você ainda não possui pacientes cadastrados.
              </p>
              <Button color="secondary" variant="flat" to="/registerPatient">
                Cadastrar Paciente
              </Button>
            </CardBody>
          </Card>
        ) : (
          <div className="grid grid-cols-1 gap-4">
            {patients.map((patient) => (
              <Card
                key={patient.id}
                shadow="sm"
                className="overflow-visible hover:scale-[1.01] transition-transform"
              >
                <CardBody className="gap-4">
                  <div className="flex flex-col md:flex-row gap-4 justify-between">
                    <div className="flex flex-col gap-2">
                      <div className="flex items-center gap-2">
                        <User className="w-5 h-5 text-secondary" />
                        <h3 className="font-semibold text-lg">
                          {patient.name}
                        </h3>
                      </div>

                      <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-2 text-default-500">
                          <Mail className="w-4 h-4" />
                          <span>{patient.email}</span>
                        </div>
                        <div className="flex items-center gap-2 text-default-500">
                          <Phone className="w-4 h-4" />
                          <span>{patient.contact1}</span>
                        </div>
                      </div>
                    </div>

                    {patient.sessions && (
                      <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-2 text-default-500">
                          <Calendar className="w-4 h-4" />
                          <span>Próxima Sessão:</span>
                        </div>
                        <div className="flex items-center gap-2 text-default-500">
                          <Clock className="w-4 h-4" />
                          <span>
                            {patient.sessions[0]?.date || "Não agendada"}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TherapistPatientsPage;
