import React, { createContext, useState, useEffect } from "react";
import { onAuthStateChanged, getIdTokenResult } from "firebase/auth";
import { auth } from "../firebase";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [isAuthLoading, setIsAuthLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setIsAuthLoading(true);
      setUser(currentUser);

      if (currentUser) {
        const tokenResult = await getIdTokenResult(currentUser);
        const userRole = tokenResult.claims.role || "patient";
        setRole(userRole);
      } else {
        setRole(null);
      }
      setIsAuthLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user, role, isAuthLoading }}>
      {children}
    </AuthContext.Provider>
  );
};
