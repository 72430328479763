import React, { useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  Chip,
  ScrollShadow,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import {
  Clock,
  Calendar,
  ChevronLeft,
  ChevronRight,
  CircleDot,
  Play,
  Check,
  Edit3,
  XCircle,
  FileText,
} from "lucide-react";
import moment from "moment";
import { parseDate, CalendarDate } from "@internationalized/date";

const SessionsList = ({
  sessions,
  onSessionSelect,
  selectedDate,
  onDateChange,
  allSessions,
}) => {
  const timelineRef = useRef(null);

  // Group all sessions by date
  const sessionsByDate = React.useMemo(() => {
    // Get date range (7 days before and after selected date)
    const startDate = moment(selectedDate.toString()).subtract(7, "days");
    const endDate = moment(selectedDate.toString()).add(7, "days");
    const dateRange = [];

    // Create array of all dates in range
    let currentDate = startDate.clone();
    while (currentDate.isSameOrBefore(endDate)) {
      dateRange.push(currentDate.format("YYYY-MM-DD"));
      currentDate.add(1, "day");
    }

    // Initialize all dates in range with empty arrays
    const groupedSessions = dateRange.reduce((acc, date) => {
      acc[date] = [];
      return acc;
    }, {});

    // Add sessions to their respective dates
    (allSessions || sessions).forEach((session) => {
      const dateKey = moment(session.date).format("YYYY-MM-DD");
      if (groupedSessions[dateKey]) {
        groupedSessions[dateKey].push(session);
      }
    });

    // Sort sessions for each day
    Object.keys(groupedSessions).forEach((date) => {
      groupedSessions[date].sort((a, b) => new Date(a.date) - new Date(b.date));
    });

    return groupedSessions;
  }, [allSessions, selectedDate, sessions]);

  // Scroll to selected date when it changes
  useEffect(() => {
    if (timelineRef.current) {
      // Simply scroll to the middle of the timeline on component mount
      const container = timelineRef.current;
      container.scrollTo({
        left: container.scrollWidth / 2 - container.offsetWidth / 2,
        behavior: "smooth",
      });
    }
  }, []); // Empty dependency array ensures this runs once when component mounts

  // Handle day selection
  const handleDaySelect = (dateStr) => {
    // Parse the string into year, month, and day parts
    const [year, month, day] = dateStr.split("-").map(Number);

    // Create a proper date object using the CalendarDate constructor
    // Month is 1-based in CalendarDate (January is 1, not 0)
    const parsedDate = new CalendarDate(year, month, Number(day));

    onDateChange(parsedDate);
  };

  // Get session status based on time
  const getSessionStatus = (session) => {
    const now = moment();
    const sessionStart = moment(session.date);
    const sessionEnd = moment(session.endTime);

    if (sessionEnd.isBefore(now)) {
      return "past";
    } else if (sessionStart.isBefore(now) && sessionEnd.isAfter(now)) {
      return "current";
    } else {
      return "upcoming";
    }
  };

  // Get chip color based on session status
  const getStatusColor = (status) => {
    switch (status) {
      case "past":
        return "default";
      case "current":
        return "success";
      case "upcoming":
        return "secondary";
      default:
        return "default";
    }
  };

  // Get status label
  const getStatusLabel = (status) => {
    switch (status) {
      case "past":
        return "Concluída";
      case "current":
        return "Em andamento";
      case "upcoming":
        return "Agendada";
      default:
        return "";
    }
  };

  // Calculate if date is today, past or future
  const getDateType = (dateStr) => {
    const today = moment().format("YYYY-MM-DD");
    if (dateStr === today) return "today";
    if (dateStr < today) return "past";
    return "future";
  };

  // Navigate to previous/next day
  const navigateDay = (direction) => {
    const newDate = moment(selectedDate.toString())
      .add(direction === "next" ? 1 : -1, "days")
      .format("YYYY-MM-DD");
    onDateChange(parseDate(newDate));
  };

  return (
    <div className="flex flex-col min-h-[400px]">
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-xl font-semibold">Linha do Tempo</h3>
        <div className="flex gap-1">
          <Button
            isIconOnly
            size="sm"
            variant="light"
            onPress={() => navigateDay("prev")}
          >
            <ChevronLeft size={18} />
          </Button>
          <Button
            isIconOnly
            size="sm"
            variant="light"
            onPress={() => navigateDay("next")}
          >
            <ChevronRight size={18} />
          </Button>
        </div>
      </div>

      {/* Date timeline */}
      <ScrollShadow
        ref={timelineRef}
        orientation="horizontal"
        className="w-full mb-2"
        hideScrollBar
      >
        <div className="flex gap-1 py-1 px-1">
          {Object.keys(sessionsByDate).map((dateStr) => {
            const dateType = getDateType(dateStr);
            const isSelected = dateStr === selectedDate.toString();
            const formattedDate = moment(dateStr).format("DD");
            const dayName = moment(dateStr).format("ddd");

            return (
              <Button
                key={dateStr}
                size="sm"
                variant={isSelected ? "solid" : "flat"}
                color={
                  dateType === "today"
                    ? "success"
                    : dateType === "past"
                    ? "default"
                    : "secondary"
                }
                className={`flex-col min-w-[50px] h-auto py-1 px-2 ${
                  isSelected ? "border-2" : ""
                }`}
                onPress={() => handleDaySelect(dateStr)}
                disabled={sessionsByDate[dateStr].length === 0}
              >
                <span className="text-xs">{dayName}</span>
                <span className="text-lg font-bold">{formattedDate}</span>
                {sessionsByDate[dateStr].length > 0 && (
                  <CircleDot className="w-3 h-3" />
                )}
              </Button>
            );
          })}
        </div>
      </ScrollShadow>

      {/* Selected day's sessions */}
      <Card className="flex-grow overflow-hidden">
        <CardBody className="p-0">
          {selectedDate && (
            <div className="p-2 bg-secondary/10 text-secondary-700 flex items-center justify-between">
              <div className="flex items-center">
                <Calendar className="w-4 h-4 mr-2" />
                <span className="font-medium">
                  {moment(selectedDate.toString()).format("dddd, D [de] MMMM")}
                </span>
              </div>
              <Chip size="sm" variant="flat" color="secondary">
                {sessionsByDate[selectedDate.toString()].length} sessões
              </Chip>
            </div>
          )}

          <ScrollShadow className="h-[250px] max-h-[calc(100%-40px)]">
            {sessionsByDate[selectedDate.toString()]?.length === 0 ? (
              <div className="text-center py-10 text-gray-500 flex flex-col items-center">
                <Calendar className="w-12 h-12 mb-2 opacity-40" />
                <p>Nenhuma sessão agendada para este dia</p>
              </div>
            ) : (
              <div className="flex flex-col gap-2 p-2">
                {sessionsByDate[selectedDate.toString()]?.map(
                  (session, index) => {
                    const status = getSessionStatus(session);
                    return (
                      <Dropdown key={session.id || `session-${index}`}>
                        <DropdownTrigger>
                          <Card
                            isPressable
                            className={`border-l-4 cursor-pointer ${
                              status === "current"
                                ? "border-success animate-pulse"
                                : status === "upcoming"
                                ? "border-secondary"
                                : "border-default"
                            }`}
                          >
                            <CardBody className="p-3">
                              <div className="flex justify-between items-start">
                                <div className="flex-1">
                                  <div className="flex items-center gap-2">
                                    <p className="font-bold">
                                      {session.patientName}
                                    </p>
                                    <Chip
                                      size="sm"
                                      color={getStatusColor(status)}
                                      variant={
                                        status === "current" ? "solid" : "flat"
                                      }
                                    >
                                      {getStatusLabel(status)}
                                    </Chip>
                                  </div>
                                  <div className="flex items-center text-sm mt-1">
                                    <Clock className="w-3 h-3 mr-1" />
                                    <span>
                                      {moment(session.date).format("HH:mm")} -{" "}
                                      {moment(session.endTime).format("HH:mm")}
                                    </span>
                                  </div>
                                </div>
                                <div className="text-xs bg-secondary-100 text-secondary-700 px-2 py-1 rounded-full">
                                  {session.duration} min
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </DropdownTrigger>
                        <DropdownMenu aria-label="Session Actions">
                          {status === "upcoming" && (
                            <DropdownItem
                              key="start"
                              startContent={
                                <Play size={16} className="text-success" />
                              }
                              onPress={() => onSessionSelect(session, "start")}
                            >
                              Iniciar Sessão
                            </DropdownItem>
                          )}
                          {status === "current" && (
                            <DropdownItem
                              key="complete"
                              startContent={
                                <Check size={16} className="text-success" />
                              }
                              onPress={() =>
                                onSessionSelect(session, "complete")
                              }
                            >
                              Concluir Sessão
                            </DropdownItem>
                          )}
                          <DropdownItem
                            key="edit"
                            startContent={
                              <Edit3 size={16} className="text-primary" />
                            }
                            onPress={() => onSessionSelect(session, "edit")}
                          >
                            Editar Sessão
                          </DropdownItem>
                          {status !== "past" && (
                            <DropdownItem
                              key="cancel"
                              className="text-danger"
                              color="danger"
                              startContent={<XCircle size={16} />}
                              onPress={() => onSessionSelect(session, "cancel")}
                            >
                              Cancelar Sessão
                            </DropdownItem>
                          )}
                          {status === "past" && (
                            <DropdownItem
                              key="notes"
                              startContent={
                                <FileText size={16} className="text-primary" />
                              }
                              onPress={() => onSessionSelect(session, "notes")}
                            >
                              Ver Notas
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    );
                  }
                )}
              </div>
            )}
          </ScrollShadow>
        </CardBody>
      </Card>
    </div>
  );
};

export default SessionsList;
