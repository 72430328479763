import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Layout from "./components/layout";
import HomePage from "./pages/homePage";
import SchedulePage from "./pages/schedulePage";
import LoginPage from "./pages/loginPage";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="schedule" element={<SchedulePage />} />
          <Route path="*" element={<HomePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
