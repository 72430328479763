import React from "react";
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";

const SessionActionsModal = ({ isOpen, onClose, session }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} placement="center" size="sm">
      <ModalContent>
        <ModalHeader>
          Ações da Sessão
          {session && (
            <p className="text-small text-default-500 mt-1">
              {session.patientName}
            </p>
          )}
        </ModalHeader>
        <ModalBody>
          <Button
            onPress={() => console.log("Iniciar sessão", session)}
            className="mb-2"
            color="primary"
            fullWidth
          >
            Iniciar sessão
          </Button>
          <Button
            onPress={() => console.log("Editar sessão", session)}
            className="mb-2"
            color="secondary"
            variant="flat"
            fullWidth
          >
            Editar sessão
          </Button>
          <Button
            onPress={() => console.log("Editar paciente", session)}
            color="default"
            variant="flat"
            fullWidth
          >
            Editar paciente
          </Button>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" variant="light" onPress={onClose}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SessionActionsModal;
