import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
} from "@nextui-org/react";
import { User } from "lucide-react";

export const SuccessModal = ({ isOpen, onClose, navigate }) => (
  <Modal isOpen={isOpen} onOpenChange={onClose}>
    <ModalContent>
      <ModalHeader className="flex items-center gap-2">
        <User className="w-5 h-5" />
        Cadastro realizado!
      </ModalHeader>
      <ModalBody>
        <p>Paciente cadastrado com sucesso!</p>
        <div className="flex justify-end mt-4">
          <Button
            color="primary"
            onPress={() => {
              onClose();
              navigate("/dashboard");
            }}
          >
            Fechar
          </Button>
        </div>
      </ModalBody>
    </ModalContent>
  </Modal>
);
