import React from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import logoLight from "../assets/psycoplam-Logo.png";
import logoDark from "../assets/psycoplam-Logo-darkMode.png";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Link,
  DropdownItem,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  Avatar,
  Button,
  Skeleton,
} from "@nextui-org/react";
import {
  Calendar,
  Home,
  LogOut,
  User,
  Users,
  ClipboardList,
  FileText,
  HeartPulse,
  ChevronDown,
} from "lucide-react";
import ThemeSwitcher from "./themeSwitcher";
import { AuthContext } from "../contexts/AuthContext";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { useTheme } from "next-themes";

// Role-based navigation configuration
const roleNavigation = {
  default: [
    { path: "/", label: "Home", icon: Home },
    { path: "/commingSoon", label: "Agendar consulta", icon: Calendar },
  ],
  therapist: [
    { path: "/", label: "Home", icon: Home },
    {
      type: "dropdown",
      label: "Area do Psicólogo",
      icon: FileText,
      items: [
        { path: "/therapistPatients", label: "Meus Pacientes", icon: User },
        { path: "/commingSoon", label: "Meus Tratamentos", icon: HeartPulse },
      ],
    },
    { path: "/dashboard", label: "Agenda", icon: Calendar },
    {
      type: "dropdown",
      label: "Cadastro",
      icon: ClipboardList,
      items: [
        { path: "/registerPatient", label: "Pacientes", icon: User },
        { path: "/commingSoon", label: "Profissionais", icon: Users },
        {
          path: "/commingSoon",
          label: "Especialidades",
          icon: ClipboardList,
        },
        { path: "/commingSoon", label: "Convenios", icon: FileText },
        { path: "/commingSoon", label: "Tratamentos", icon: HeartPulse },
      ],
    },
  ],
};

const Header = () => {
  const { theme } = useTheme();
  const logoSrc = theme === "dark" ? logoDark : logoLight;
  const location = useLocation();
  const navigate = useNavigate();
  const { user, role, isAuthLoading } = React.useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const isActiveRoute = (path) => location.pathname === path;

  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.location.reload();
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
    }
  };

  const getNavigationItems = () => {
    return roleNavigation[role === "therapist" ? "therapist" : "default"];
  };

  const getMobileItems = () => {
    const items = getNavigationItems();
    return items.flatMap((item) =>
      item.type === "dropdown" ? item.items : item
    );
  };

  const renderDesktopNavItem = (item) => {
    if (isAuthLoading) {
      return (
        <NavbarItem key={item.label || item.path}>
          <Skeleton className="h-8 w-24 rounded-lg" />
        </NavbarItem>
      );
    }

    if (item.type === "dropdown") {
      return (
        <NavbarItem key={item.label}>
          <Dropdown>
            <DropdownTrigger>
              <Button
                variant="light"
                className="font-medium data-[hover=true]:bg-default-0 gap-1"
                color="foreground"
              >
                <item.icon className="w-4 h-4" />
                {item.label}
                <ChevronDown className="w-4 h-4" />
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label={item.label}>
              {item.items.map((subItem) => (
                <DropdownItem
                  key={subItem.path}
                  as={RouterLink}
                  to={subItem.path}
                  startContent={<subItem.icon className="w-4 h-4" />}
                  color={isActiveRoute(subItem.path) ? "secondary" : "default"}
                >
                  {subItem.label}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </NavbarItem>
      );
    }

    return (
      <NavbarItem key={item.path} isActive={isActiveRoute(item.path)}>
        <Link
          as={RouterLink}
          to={item.path}
          color={isActiveRoute(item.path) ? "secondary" : "foreground"}
          className="font-medium flex items-center gap-1"
        >
          <item.icon className="w-4 h-4" />
          {item.label}
        </Link>
      </NavbarItem>
    );
  };

  return (
    <Navbar
      onMenuOpenChange={setIsMenuOpen}
      className="bg-background/70 backdrop-blur-md border-b border-divider"
      maxWidth="xl"
      position="sticky"
    >
      <NavbarContent>
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="sm:hidden"
        />
        <NavbarBrand>
          <Link
            as={RouterLink}
            to="/"
            className="flex items-center gap-2"
            color="foreground"
          >
            <img
              height="36"
              width="36"
              src={logoSrc}
              alt="Psycoplam Logo"
              className="rounded-full"
            />
            <p className="font-bold text-inherit">Psycoplam</p>
          </Link>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="hidden sm:flex gap-8" justify="center">
        {getNavigationItems().map(renderDesktopNavItem)}
      </NavbarContent>

      <NavbarContent justify="end" className="gap-2">
        <ThemeSwitcher />

        <Dropdown placement="bottom-end">
          <DropdownTrigger>
            {isAuthLoading ? (
              <Skeleton className="rounded-full">
                <Avatar className="w-8 h-8 opacity-0" />
              </Skeleton>
            ) : (
              <Avatar
                isBordered
                as="button"
                className="transition-transform"
                color="secondary"
                name={user?.displayName}
                size="sm"
                showFallback
                src={user?.photoURL}
                fallback={<User className="w-4 h-4" />}
              />
            )}
          </DropdownTrigger>
          <DropdownMenu aria-label="Profile Actions" variant="flat">
            {isAuthLoading ? (
              <DropdownItem key="loading" className="h-16 gap-2" isReadOnly>
                <Skeleton className="h-4 w-32 rounded-lg" />
                <Skeleton className="h-4 w-24 rounded-lg" />
              </DropdownItem>
            ) : user ? (
              <>
                <DropdownItem key="profile" className="h-16 gap-2" isReadOnly>
                  <div className="flex flex-col gap-1">
                    <p className="font-semibold">Bem-vindo,</p>
                    <p className="font-semibold">{user.displayName}</p>
                    {role === "therapist" && (
                      <p className="text-xs text-secondary">Administrador</p>
                    )}
                  </div>
                </DropdownItem>
                {role === "therapist" && (
                  <DropdownItem key="dashboard" as={RouterLink} to="/dashboard">
                    Dashboard
                  </DropdownItem>
                )}
                <DropdownItem
                  key="logout"
                  color="danger"
                  startContent={<LogOut className="w-4 h-4" />}
                  onPress={handleLogout}
                >
                  Sair
                </DropdownItem>
              </>
            ) : (
              <DropdownItem key="login" onPress={() => navigate("/login")}>
                Entrar
              </DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
      </NavbarContent>

      <NavbarMenu className="pt-6">
        {isAuthLoading ? (
          [1, 2, 3].map((i) => (
            <NavbarMenuItem key={i}>
              <Skeleton className="h-8 w-full rounded-lg" />
            </NavbarMenuItem>
          ))
        ) : (
          <>
            {getMobileItems().map((item) => (
              <NavbarMenuItem key={item.path}>
                <Link
                  as={RouterLink}
                  to={item.path}
                  color={isActiveRoute(item.path) ? "secondary" : "foreground"}
                  className="w-full flex items-center gap-2"
                >
                  <item.icon className="w-4 h-4" />
                  {item.label}
                </Link>
              </NavbarMenuItem>
            ))}
            {user && (
              <NavbarMenuItem>
                <Button
                  color="danger"
                  variant="flat"
                  onPress={handleLogout}
                  startContent={<LogOut className="w-4 h-4" />}
                  className="w-full justify-start"
                >
                  Sair
                </Button>
              </NavbarMenuItem>
            )}
          </>
        )}
      </NavbarMenu>
    </Navbar>
  );
};

export default Header;
