import React from "react";
import { Button, Divider } from "@nextui-org/react";
import { Plus, CalendarIcon, Clock } from "lucide-react";

const ActionsPanel = ({
  onNewSession,
  patientsCount,
  todaySessions,
  totalSessions,
}) => {
  return (
    <div className="flex flex-col gap-4 pr-4 min-h-[400px]">
      <h3 className="text-xl font-semibold">Ações</h3>
      <Button
        color="secondary"
        startContent={<Plus className="h-5 w-5" />}
        onPress={onNewSession}
        className="mb-2"
      >
        Agendar Nova Sessão
      </Button>
      <Button
        variant="flat"
        startContent={<CalendarIcon className="h-5 w-5" />}
      >
        Ver Semana
      </Button>
      <Button variant="flat" startContent={<Clock className="h-5 w-5" />}>
        Histórico de Sessões
      </Button>

      <Divider className="my-2" />

      <div className="mt-4">
        <h4 className="text-lg font-medium mb-2">Resumo</h4>
        <p>Total de pacientes: {patientsCount}</p>
        <p>Sessões hoje: {todaySessions}</p>
        <p>Sessões agendadas: {totalSessions}</p>
      </div>
    </div>
  );
};

export default ActionsPanel;
