import React from 'react';
import { Menu, MenuItem, Image, Segment } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation

import logo from "../assets/psycoplam-Logo.png";

const Header = () => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path;


  return (
    <Segment basic>
      <Menu secondary stackable>
        <MenuItem as={Link} to="/">
          <Image size="mini" src={logo} />
        </MenuItem>
        <MenuItem
          as={Link}
          to="/"
          name="home"
          active={isActive('/')}
        >
          Home
        </MenuItem>

        <MenuItem
          as={Link}
          to="schedule"
          name="schedule"
          active={isActive('/schedule')}
        >
          Agendar consulta
        </MenuItem>

        {/* <MenuItem
          as={Link}
          to="login"
          name="login"
          active={activeItem === "login"}
          onClick={() => setActiveItem("login")}
        >
          Log in
        </MenuItem> */}
      </Menu>
    </Segment>
  );
};
export default Header;
