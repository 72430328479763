import React from "react";
import { useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

const PageTransition = ({ children }) => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      position: "absolute",
      width: "100%",
      transform: "scale(0.98)",
    },
    in: {
      opacity: 1,
      position: "relative",
      transform: "scale(1)",
    },
    out: {
      opacity: 0,
      position: "absolute",
      transform: "scale(1.02)",
    },
  };

  return (
    <div style={{ position: "relative" }}>
      <AnimatePresence mode="wait">
        <motion.div
          key={location.pathname}
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={{
            type: "tween",
            ease: "easeInOut",
            duration: 0.3,
          }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default PageTransition;
