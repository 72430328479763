import React, { useState, useEffect } from "react";
import moment from "moment";
import { parseDate } from "@internationalized/date";
import {
  Alert,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DatePicker,
  Select,
  SelectItem,
  RadioGroup,
  Radio,
  Card,
} from "@nextui-org/react";
import PersonalDataSection from "../patient/personalDataSection";

const NewSessionModal = ({
  isOpen,
  onClose,
  onSubmit,
  selectedDate,
  patients,
  isLoading,
  formError,
}) => {
  // Form states
  const [sessionData, setSessionData] = useState({
    sessionDate: selectedDate,
    sessionTime: "",
    sessionDuration: 60,
  });
  const [patientOption, setPatientOption] = useState("new");
  const [selectedPatient, setSelectedPatient] = useState(null);

  const durationOptions = [
    { label: "30 minutos", value: 30 },
    { label: "45 minutos", value: 45 },
    { label: "60 minutos", value: 60 },
    { label: "90 minutos", value: 90 },
  ];

  const handleSessionInputChange = (field, value) => {
    setSessionData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting form with patient:", selectedPatient); // Debug log
    onSubmit(e, {
      sessionData,
      patientOption,
      selectedPatient,
    });
  };

  // Reset form when modal opens
  useEffect(() => {
    if (isOpen) {
      setSessionData({
        sessionDate: selectedDate,
        sessionTime: "",
        sessionDuration: 60,
      });
      setPatientOption("new");
      setSelectedPatient(null);

      // If there are patients and we switch to existing, pre-select the first patient
      if (patients.length > 0) {
        const firstPatientId = patients[0].id;
        console.log("Pre-selecting first patient:", firstPatientId); // Debug log
      }
    }
  }, [isOpen, selectedDate, patients]);

  // Function to generate time slots
  const generateTimeSlots = (date) => {
    // Start time (8:00 AM)
    const startTime = moment().hour(8).minute(0);
    // End time (6:00 PM)
    const endTime = moment().hour(18).minute(0);

    const slots = [];
    let currentTime = startTime.clone();

    // Generate 30-minute slots between start and end times
    while (currentTime <= endTime) {
      slots.push(currentTime.format("HH:mm"));
      currentTime.add(30, "minutes");
    }

    return slots;
  };

  // Function to get disabled time slots (returns array of keys)
  const getDisabledTimeSlots = (selectedDate) => {
    if (!selectedDate) return [];

    // Convert date picker value to moment object for comparison
    const selectedMoment = moment(selectedDate.toString());
    const currentMoment = moment();

    // If selected date is in the future, no time slots should be disabled
    if (
      selectedMoment.format("YYYY-MM-DD") !== currentMoment.format("YYYY-MM-DD")
    ) {
      return [];
    }

    // If it's today, disable all time slots in the past
    const disabledSlots = [];
    const timeSlots = generateTimeSlots();

    timeSlots.forEach((timeSlot) => {
      const [hours, minutes] = timeSlot.split(":").map(Number);
      const slotMoment = moment().hour(hours).minute(minutes);

      if (slotMoment.isBefore(currentMoment)) {
        disabledSlots.push(timeSlot);
      }
    });

    return disabledSlots;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} placement="center" size="lg">
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalHeader>Agendar Nova Sessão</ModalHeader>
          <ModalBody>
            {formError && <Alert color="error" title={formError} />}
            <RadioGroup
              label="Paciente"
              value={patientOption}
              onValueChange={(value) => {
                setPatientOption(value);
                if (value === "existing" && patients.length > 0) {
                  const firstPatientId = patients[0].id;
                  setSelectedPatient(firstPatientId);
                  console.log("Selected first patient:", firstPatientId); // Debug log
                }
              }}
            >
              <Radio value="new">Novo paciente</Radio>
              <Radio value="existing">Paciente existente</Radio>
            </RadioGroup>

            {patientOption === "new" && <PersonalDataSection />}

            <Card
              className="p-6 grid grid-cols-1 md:grid-cols-1 gap-4"
              shadow="sm"
            >
              {patientOption === "existing" && (
                <>
                  {patients.length === 0 ? (
                    <Alert color="warning" title="Nenhum paciente encontrado" />
                  ) : (
                    <Select
                      label="Selecione o paciente"
                      placeholder="Escolha um paciente"
                      value={selectedPatient}
                      onChange={(e) => {
                        const value = e.target.value;
                        setSelectedPatient(value);
                      }}
                      isRequired
                    >
                      {patients.map((patient) => (
                        <SelectItem key={patient.id} value={patient.id}>
                          {patient.name}
                        </SelectItem>
                      ))}
                    </Select>
                  )}
                </>
              )}

              <DatePicker
                label="Data da Sessão"
                value={sessionData.sessionDate}
                onChange={(value) => {
                  handleSessionInputChange("sessionDate", value);
                  // Reset time when date changes
                  handleSessionInputChange("sessionTime", "");
                }}
                isRequired
                locale="pt-BR"
                minValue={parseDate(moment().format("YYYY-MM-DD"))}
              />

              <Select
                label="Horário da Sessão"
                placeholder="Selecione o horário"
                value={sessionData.sessionTime}
                onChange={(e) =>
                  handleSessionInputChange("sessionTime", e.target.value)
                }
                isRequired
                isDisabled={!sessionData.sessionDate}
                disabledKeys={getDisabledTimeSlots(sessionData.sessionDate)}
              >
                {generateTimeSlots().map((timeSlot) => (
                  <SelectItem key={timeSlot} value={timeSlot}>
                    {timeSlot}
                  </SelectItem>
                ))}
              </Select>

              <Select
                label="Duração da Sessão"
                value={sessionData.sessionDuration}
                onChange={(e) =>
                  handleSessionInputChange(
                    "sessionDuration",
                    Number(e.target.value)
                  )
                }
                placeholder="Selecione a duração"
                isRequired
              >
                {durationOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </Select>
            </Card>
          </ModalBody>

          <ModalFooter>
            <Button color="danger" variant="light" onPress={onClose}>
              Cancelar
            </Button>
            <Button type="submit" color="secondary" isLoading={isLoading}>
              Salvar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default NewSessionModal;
