import React from "react";

const LoginPage = () => {
  return (
    <div>
      <h1>Login</h1>
      <p>Log in to your account</p>
    </div>
  );
};

export default LoginPage;
