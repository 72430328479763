import React, { useEffect, useState } from "react";
import { I18nProvider } from "@react-aria/i18n";
import { Button, Calendar } from "@nextui-org/react";
import { CalendarDate } from "@internationalized/date";
import { CalendarSyncIcon } from "lucide-react";

const CalendarSection = ({ selectedDate, handleDateChange }) => {
  // Track the visible month to ensure it stays in sync with selectedDate
  const [visibleMonth, setVisibleMonth] = useState(() => {
    const date = new Date(selectedDate.toString());
    return new CalendarDate(date.getFullYear(), date.getMonth() + 1, 1);
  });

  // Update visibleMonth when selectedDate changes
  useEffect(() => {
    // Create a new CalendarDate for the visible month
    const newMonth = new CalendarDate(selectedDate.year, selectedDate.month, 1);

    // Only update if the month or year has changed
    if (
      newMonth.month !== visibleMonth.month ||
      newMonth.year !== visibleMonth.year
    ) {
      setVisibleMonth(newMonth);
      console.log("Updating visible month to:", newMonth);
    }
  }, [selectedDate, visibleMonth]);

  const goToToday = () => {
    const today = new Date();
    const todayCalendarDate = new CalendarDate(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate()
    );
    handleDateChange(todayCalendarDate);
  };

  return (
    <div className="flex flex-col items-center min-h-[400px]">
      <h3 className="text-xl font-semibold mb-4">Calendário</h3>
      <div className="h-[320px] flex items-center w-full justify-center">
        <I18nProvider locale="pt-BR">
          <Calendar
            key={`calendar-${selectedDate.year}-${selectedDate.month}`}
            showMonthAndYearPickers
            weekdayStyle="short"
            color="secondary"
            value={selectedDate}
            onChange={handleDateChange}
            selectionMode="single"
            visibleMonth={visibleMonth}
            onVisibleMonthChange={setVisibleMonth}
            fixedWeeks={true}
          />
        </I18nProvider>
      </div>
      <Button className="m-4" color="secondary" onPress={goToToday}>
        <CalendarSyncIcon size={18} className="mr-2" />
        Hoje
      </Button>
    </div>
  );
};

export default CalendarSection;
