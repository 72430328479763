import React, { useState } from "react";
import { Card, CardHeader, Input } from "@nextui-org/react";
import cep from "cep-promise";

const AddressSection = () => {
  const [zipCode, setZipCode] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [stateValue, setStateValue] = useState("");

  const handleCepBlur = () => {
    const cleanedCep = zipCode.replace(/\D/g, "");
    if (cleanedCep.length !== 8) {
      setZipCodeError("CEP deve conter 8 dígitos");
      return;
    }

    cep(zipCode)
      .then((result) => {
        setAddress(result.street || "");
        setCity(result.city || "");
        setStateValue(result.state || "");
        setZipCodeError("");
      })
      .catch((error) => {
        console.error("Error fetching CEP data:", error);
        // Handle validation errors and service errors respectively
        if (error.type === "validation_error") {
          setZipCodeError(error.message);
        } else {
          setZipCodeError("CEP não encontrado");
        }
      });
  };

  return (
    <Card className="p-6 grid grid-cols-1 md:grid-cols-2 gap-4" shadow="sm">
      <CardHeader className="p-0 col-span-full flex-col !items-start">
        <h4 className="font-medium text-large">Endereço e Contato</h4>
      </CardHeader>
      <Input
        isRequired
        label="CEP"
        labelPlacement="outside"
        name="zipCode"
        placeholder="00000-000"
        type="text"
        value={zipCode}
        onChange={(e) => setZipCode(e.target.value)}
        onBlur={handleCepBlur}
        status={zipCodeError ? "error" : "default"}
        validate={(value) =>
          !value ? "Campo obrigatório" : zipCodeError || true
        }
      />
      <Input
        isRequired
        label="Endereço"
        labelPlacement="outside"
        name="address"
        placeholder="Digite o endereço"
        type="text"
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        validate={(value) => (!value ? "Campo obrigatório" : true)}
      />
      <Input
        isRequired
        label="Cidade"
        labelPlacement="outside"
        name="city"
        placeholder="Cidade"
        type="text"
        value={city}
        onChange={(e) => setCity(e.target.value)}
        validate={(value) => (!value ? "Campo obrigatório" : true)}
      />
      <Input
        isRequired
        label="Estado"
        labelPlacement="outside"
        name="state"
        placeholder="Estado"
        type="text"
        value={stateValue}
        onChange={(e) => setStateValue(e.target.value)}
        validate={(value) => (!value ? "Campo obrigatório" : true)}
      />
      <Input
        isRequired
        label="Telefone"
        labelPlacement="outside"
        name="phoneNumber"
        placeholder="(11) 9999-9999"
        type="text"
        validate={(value) => (!value ? "Campo obrigatório" : true)}
      />
      <Input
        isRequired
        label="E-mail"
        labelPlacement="outside"
        name="email"
        placeholder="Digite seu e-mail"
        type="email"
        validate={(value) => (!value ? "Campo obrigatório" : true)}
      />
    </Card>
  );
};

export default AddressSection;
