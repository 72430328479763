import React from "react";
import { Container, List, Segment, Image } from "semantic-ui-react";
import footerLogo from "../assets/psycoplam-Logo2.png";

const Footer = () => {
  return (
    <Segment
      style={{
        width: "100%",
      }}
    >
      <Container textAlign="center">
        <List horizontal divided>
          <List.Item>
            <Image size="small" src={footerLogo} />
          </List.Item>
          <List.Item>Curitiba, 2024</List.Item>
        </List>
      </Container>
    </Segment>
  );
};

export default Footer;
