import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import PageTransition from "./pageTransition";

const Layout = () => {
  const location = useLocation();
  const [isRouteChanging, setIsRouteChanging] = React.useState(false);

  React.useEffect(() => {
    setIsRouteChanging(true);
    const timer = setTimeout(() => setIsRouteChanging(false), 50);
    return () => clearTimeout(timer);
  }, [location.key]);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow relative">
        <PageTransition key={location.key}>
          {!isRouteChanging && (
            <div className="w-full h-full">
              <Outlet />
              <Footer />
            </div>
          )}
        </PageTransition>
      </main>
    </div>
  );
};

export default Layout;
