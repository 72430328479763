import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const CEP_API_BASE_URL = process.env.REACT_APP_CEP_API_BASE_URL;

// ====================
// External APIs
// ====================
export async function getCEP(cep) {
  return axios.get(`${CEP_API_BASE_URL}/${cep}/json`);
}

// ====================
// Therapist Endpoints
// ====================
export async function getAllTherapists() {
  return axios.get(`${BASE_URL}/therapists`);
}

export async function getTherapist(therapistId) {
  return axios.get(`${BASE_URL}/therapists/therapistId/${therapistId}`);
}

export async function getTherapistByFirebaseId(firebaseId) {
  return axios.get(`${BASE_URL}/therapists/firebaseId/${firebaseId}`);
}

export async function createTherapist(therapistData) {
  return axios.post(`${BASE_URL}/therapists`, therapistData);
}

export async function updateTherapist(therapistId, therapistData) {
  return axios.put(`${BASE_URL}/therapists/${therapistId}`, therapistData);
}

export async function deleteTherapist(therapistId) {
  return axios.delete(`${BASE_URL}/therapists/${therapistId}`);
}

// ====================
// Patient Endpoints
// ====================
export async function listAllPatients() {
  return axios.get(`${BASE_URL}/patients`);
}

export async function getAllPatientsForTherapist(therapistRegistrationNumber) {
  return axios.get(
    `${BASE_URL}/patients/therapist?therapistRegistrationNumber=${therapistRegistrationNumber}`
  );
}

export async function getPatientById(patientId) {
  return axios.get(`${BASE_URL}/patients/${patientId}`);
}

export async function createPatient(patientData) {
  return axios.post(`${BASE_URL}/patients`, patientData);
}

export async function updatePatient(patientId, patientData) {
  return axios.put(`${BASE_URL}/patients/${patientId}`, patientData);
}

export async function deletePatient(patientId) {
  return axios.delete(`${BASE_URL}/patients/${patientId}`);
}

// ====================
// Session Endpoints
// ====================
export async function createSession(sessionData, patientId) {
  return axios.post(`${BASE_URL}/patients/${patientId}/sessions`, sessionData);
}

export async function getPatientSessions(patientId) {
  return axios.get(`${BASE_URL}/patients/${patientId}/sessions`);
}

export async function getSessionById(patientId, sessionId) {
  return axios.get(`${BASE_URL}/patients/${patientId}/sessions/${sessionId}`);
}

export async function updateSession(patientId, sessionId, sessionData) {
  return axios.put(
    `${BASE_URL}/patients/${patientId}/sessions/${sessionId}`,
    sessionData
  );
}

export async function deleteSession(patientId, sessionId) {
  return axios.delete(
    `${BASE_URL}/patients/${patientId}/sessions/${sessionId}`
  );
}
