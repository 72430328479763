import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import { Container } from "semantic-ui-react";
import 'fomantic-ui-css/semantic.css';

const Layout = () => {
  return (
    <Container
      fluid
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
      }}
    >
      <Header />
      <Outlet />
      <Footer />
    </Container>
  );
};

export default Layout;
