import React from "react";
import { Card, CardHeader, Input } from "@nextui-org/react";

const AdditionalInfoSection = () => (
  <Card className="p-6 grid grid-cols-1 md:grid-cols-2 gap-4" shadow="sm">
    <CardHeader className="p-0 col-span-full flex-col !items-start">
      <h4 className="font-medium text-large">Informações Adicionais</h4>
    </CardHeader>
    <Input
      label="Profissão"
      labelPlacement="outside"
      name="profession"
      placeholder="Ex: Designer"
      type="text"
    />
    <Input
      label="Nível de Escolaridade"
      labelPlacement="outside"
      name="educationLevel"
      placeholder="Ex: Superior completo"
      type="text"
    />
    <Input
      label="Indicado Por"
      labelPlacement="outside"
      name="referredBy"
      placeholder="Ex: Indicação X"
      type="text"
    />
    <Input
      label="Cartão do Convênio"
      labelPlacement="outside"
      name="insuranceCard"
      placeholder="Digite o número do cartão do convênio"
      type="text"
    />
  </Card>
);

export default AdditionalInfoSection;
