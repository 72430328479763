import React from "react";
import "fomantic-ui-css/semantic.css";
import { Container, Header, Button, Segment, List } from "semantic-ui-react";

const HomePage = () => {
  return (
    <Container>
      <Header as="h1">Psycoplam, especialistas em Saúde Mental</Header>
      <Segment>
        <Header as="h2">Quem somos</Header>
        <p>
          Possuímos uma rede de profissionais especializados na área psicológica e psiquiátrica.
          Realizamos atendimentos com foco na promoção da qualidade de vida e Saúde Mental.
        </p>
        <Header as="h2">Visão</Header>
        <p>
          Ser referência em Saúde Mental, com foco no desenvolvimento humano, assim promovendo recursos psíquicos e responsabilidade social.
        </p>
        <Header as="h2">Missão</Header>
        <p>
          Promover saúde física e mental, desenvolver pessoas emocionalmente para gerar qualidade de vida.
        </p>
        <Header as="h2">Valores</Header>
        <p>
          Ética, Integridade, Empatia, Respeito ao próximo e Responsabilidade.
        </p>
        <p>
          Com compromisso social, nosso objetivo é oferecer serviços e recursos que proporcionem Saúde Mental.
        </p>
        <Header as="h2">Conte com a nossa equipe para lhe atender, nossos serviços:</Header>
        <List bulleted>
          <List.Item>Psicoterapia</List.Item>
          <List.Item>Avaliação Psicológica</List.Item>
          <List.Item>Neuropsicologia</List.Item>
          <List.Item>Psiquiatria</List.Item>
        </List>
        <Button primary onClick={() => window.location.href = "/schedule"} color="purple">
          Agendar sua consulta
        </Button>
      </Segment>
    </Container>
  );
};

export default HomePage;