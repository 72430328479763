import React from "react";
import { Card, CardHeader, Input, Select, SelectItem } from "@nextui-org/react";
import { cpf } from "cpf-cnpj-validator";

const maritalStatusOptions = [
  { label: "Solteiro(a)", value: "solteiro" },
  { label: "Casado(a)", value: "casado" },
  { label: "Divorciado(a)", value: "divorciado" },
  { label: "Viúvo(a)", value: "viuvo" },
];

const genderOptions = [
  { label: "Feminino", value: "feminino" },
  { label: "Masculino", value: "masculino" },
];

const PersonalDataSection = () => (
  <Card className="p-6 grid grid-cols-1 md:grid-cols-2 gap-4" shadow="sm">
    <CardHeader className="p-0 col-span-full flex-col !items-start">
      <h4 className="font-medium text-large">Dados Pessoais</h4>
    </CardHeader>
    <Input
      isRequired
      label="Nome Completo"
      labelPlacement="outside"
      name="fullName"
      placeholder="Digite seu nome completo"
      type="text"
      validate={(value) => (!value ? "Campo obrigatório" : true)}
    />
    <Input
      label="CPF"
      labelPlacement="outside"
      name="cpf"
      placeholder="000.000.000-00"
      maxLength={11}
      type="text"
      validate={(value) => {
        if (!value) return true;
        else if (cpf.isValid(value)) return true;
        return "CPF invalido";
      }}
    />
    <Input
      isRequired
      label="Data de Nascimento"
      labelPlacement="outside"
      name="birthDate"
      type="date"
      validate={(value) => (!value ? "Campo obrigatório" : true)}
    />
    <Select
      isRequired
      label="Estado Civil"
      labelPlacement="outside"
      name="maritalStatus"
      placeholder="Selecione o estado civil"
      options={maritalStatusOptions}
      validate={(value) => (!value ? "Campo obrigatório" : true)}
    >
      {maritalStatusOptions.map((status) => (
        <SelectItem key={status.label} value={status.value}>
          {status.label}
        </SelectItem>
      ))}
    </Select>
    <Select
      isRequired
      label="Gênero"
      labelPlacement="outside"
      name="gender"
      placeholder="Selecione o gênero"
      validate={(value) => (!value ? "Campo obrigatório" : true)}
    >
      {genderOptions.map((gender) => (
        <SelectItem key={gender.label} value={gender.value}>
          {gender.label}
        </SelectItem>
      ))}
    </Select>
  </Card>
);

export default PersonalDataSection;
