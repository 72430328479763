import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../firebase";
import {
  Card,
  CardBody,
  Tabs,
  Tab,
  Button,
  CardHeader,
} from "@nextui-org/react";
import { AlertCircle } from "lucide-react";
import GoogleIcon from "../assets/GoogleIcon";

const LoginPage = () => {
  const [selectedTab, setSelectedTab] = useState("patient");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const googleProvider = new GoogleAuthProvider();

  const handleGoogleLogin = async (userType) => {
    setIsLoading(true);
    setError("");

    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log(result);

      if (userType === "therapist") {
        // TODO: Call therapist verification endpoint
        // const response = await verifyTherapist(result.user.email);
        // if (!response.isVerified) {
        //   throw new Error("Email não autorizado para acesso como terapeuta");
        // }
      }

      navigate("/");
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-[80vh] p-4">
      <Card className="max-w-md w-full">
        <CardHeader className="flex flex-col gap-2">
          <h2 className="text-2xl font-bold text-center">
            Bem-vindo à Psycoplam
          </h2>
          <p className="text-default-500 text-center">
            Escolha seu tipo de acesso abaixo
          </p>
        </CardHeader>

        <CardBody>
          <Tabs
            selectedKey={selectedTab}
            onSelectionChange={setSelectedTab}
            variant="bordered"
            aria-label="Login options"
            className="mb-6"
            fullWidth
          >
            <Tab
              key="patient"
              title={<div className="flex items-center gap-2">Paciente</div>}
            >
              <div className="flex flex-col gap-4 py-4">
                <h3 className="text-center text-default-700">
                  Acesso para Pacientes
                </h3>
                <p className="text-center text-default-500 text-sm">
                  Use sua conta Google para acessar como paciente
                </p>
                <Button
                  startContent={<GoogleIcon />}
                  className="w-full"
                  color="primary"
                  variant="bordered"
                  onPress={() => handleGoogleLogin("patient")}
                  isLoading={isLoading && selectedTab === "patient"}
                >
                  Continuar com Google
                </Button>
              </div>
            </Tab>
            <Tab
              key="therapist"
              title={<div className="flex items-center gap-2">
                Psicólogo(a)
                 </div>}
            >
              <div className="flex flex-col gap-4 py-4">
                <h3 className="text-center text-default-700">
                  Acesso para Psicólogos(as)
                </h3>
                <p className="text-center text-default-500 text-sm">
                  Use seu email profissional cadastrado para acessar
                </p>
                <Button
                  startContent={<GoogleIcon />}
                  className="w-full"
                  color="secondary"
                  variant="bordered"
                  onPress={() => handleGoogleLogin("therapist")}
                  isLoading={isLoading && selectedTab === "therapist"}
                >
                  Entrar como Psicólogo(a)
                </Button>
              </div>
            </Tab>
          </Tabs>

          {error && (
            <div className="flex items-center gap-2 p-2 mt-4 text-sm text-danger rounded-lg bg-danger-50">
              <AlertCircle className="w-4 h-4" />
              <span>{error}</span>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default LoginPage;
