import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Layout from "./components/layout";
import HomePage from "./pages/homePage";
import SchedulePage from "./pages/schedulePage";
import LoginPage from "./pages/loginPage";
import DashboardPage from "./pages/dashboardPage.js";
import NotImplementedPage from "./pages/notImplementedPage.js";
import UnauthorizedPage from "./pages/unauthorizedPage.js";
import RegisterPatientPage from "./pages/registerPages/registerPatientPage.js";
import TherapistPatientsPage from "./pages/therapistAreaPages/therapistPatientsPage.js";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="schedule" element={<SchedulePage />} />
          <Route path="commingSoon" element={<NotImplementedPage />} />
          <Route path="unauthorized" element={<UnauthorizedPage />} />
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="registerPatient" element={<RegisterPatientPage />} />
          <Route path="therapistPatients" element={<TherapistPatientsPage />} />
          <Route path="*" element={<HomePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
